<template>
  <TargetKpiKorporatForm mode="Ubah" module="Target Indikator Kinerja"> </TargetKpiKorporatForm>
</template>

<script>
import TargetKpiKorporatForm from './form-target';

const TargetKpiKorporatUpdate = {
  name: 'TargetKpiKorporatUpdate',
  components: { TargetKpiKorporatForm },
};

export default TargetKpiKorporatUpdate;
</script>
